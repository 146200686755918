import axios from 'axios';

// Fetch the API URLs and Key from environment variables
const API_URL = process.env.REACT_APP_JGATE_JOURNALS_API_URL;
const API_KEY = process.env.REACT_APP_JGATE_API_KEY;

const fetchJGateResults = async (searchQuery, pageNumber = 1, filters = {}, sorting) => {
  try {
    const response = await axios.get(API_URL, {
      params: {
        page: pageNumber - 1,
        rows: 20,
        journal_name: searchQuery,
        ...filters,
      },
      headers: {
        'Api-Key': API_KEY,
      },
    });

    const data = response.data.data || {};
    const results = (data.docs || []).map((item) => ({
      id: item.resourcemaster_id,
      title: item.resource_name,
      publisher: item.publisher_name?.join(', ') || 'Unknown',
      eissn: item.eissn || 'N/A',
      pissn: item.pissn || 'N/A',
      country: item.primary_publisher_country || 'Unknown',
      accessType: item.is_openaccess ? 'Open Access' : 'Restricted',
      description: item.aboutresource || '',
      yearRange: `${item.content_start} - ${item.content_end}`,
      subjects: item.subjects_name_l1?.join(', ') || '',
      detailLink: `https://jgatenext-com.demo.remotlog.com/BrowseJournalTableSearch/BrowseJournalSearch/?resourceId=${item.resourcemaster_id}`,
    }));

    // Calculate facets
    const facets = {
      subjects: {},
      publishers: {},
      countries: {}
    };

    (data.docs || []).forEach((doc) => {
      // Count subjects
      if (doc.subjects_name_l3) {
        doc.subjects_name_l3.forEach((subject) => {
          facets.subjects[subject] = (facets.subjects[subject] || 0) + 1;
        });
      }

      // Count publishers
      if (doc.publisher_name) {
        doc.publisher_name.forEach((publisher) => {
          facets.publishers[publisher] = (facets.publishers[publisher] || 0) + 1;
        });
      }

      // Count countries
      const country = doc.primary_publisher_country;
      if (country) {
        facets.countries[country] = (facets.countries[country] || 0) + 1;
      }
    });

    return { results, total: data.hits || results.length, facets };
  } catch (error) {
    console.error('Error fetching J-Gate journal data:', error);
    throw new Error('Failed to fetch journal data.');
  }
};

export default fetchJGateResults;

