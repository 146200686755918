import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ArticleDetailsDSpace from '../pages/ArticleDetails_DSpace';
import './Cardgrid.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Cardgrid = () => {
  // Manually defined books
  const books = [
    {
      id: 1,
      title: 'The Law of Civilization and Decay- An Essay on History',
      author: 'Brooks Adams',
      uuid: '1a99dc3d-afa9-4f92-b81a-59c7c2a7e461',
      thumbnail: '/9788184307098.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 2,
      title: 'The Law and the Lady',
      author: 'Wilkie Collins',
      uuid: '7cf5f745-3277-4bd6-8fe4-16a88bd6175b',
      thumbnail: '/9788184306412.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 3,
      title: 'English Law and the Renaissance',
      author: 'Frederic William Maitland',
      uuid: 'ad8831cf-9b6d-4cab-ba70-76837a862e5e',
      thumbnail: '/9788184305992.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 4,
      title: 'Laws',
      author: 'Plato',
      uuid: 'a249d3f0-3fed-4be2-8254-3f7b635a91dd',
      thumbnail: '/BP-2020-005-0058.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 5,
      title: 'Commentary on the Constitution of India',
      author: 'P.K. Agrawal, K.N. Chaturvedi',
      uuid: 'c2201b93-e70f-4afd-994d-9dbd02927dc4',
      thumbnail: '/9789386231765.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 6,
      title: 'Evolution of Indian Judiciary',
      author: 'Dr Lm Singhvi',
      uuid: 'c4cb37df-220f-4887-a138-e579fb42a884',
      thumbnail: '/9789350488195.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 7,
      title: 'Facts and Law on Article 370 & 35A',
      author: 'Monika Arora',
      uuid: 'ea6cec94-0424-412e-b303-c56a4de1c0ba',
      thumbnail: '/9789353226237.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 7,
      title: 'Science Needs for Microbial Forensics: Developing Initial International Research Priorities',
      author: 'Committee on Science Needs for Microbial Forensics',
      uuid: 'b9ac8e57-95bf-4a2e-b5a7-515f5fac9992',
      thumbnail: '/Bookshelf_NBK234876_1.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 7,
      title: 'Genetic Surveillance and Crime Control',
      author: 'Helena Machado',
      uuid: 'a74c6a17-4823-4f1d-a216-0f2f2c2f4d5d',
      thumbnail: '/external_content_14471.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 8,
      title: 'Cyber Security Politics',
      author: 'Myriam Dunn Cavelty',
      uuid: '9eed5186-c811-4e39-9da5-42ebda88d405',
      thumbnail: '/external_content_8312.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 9,
      title: 'Cyberspace and Instability',
      author: 'James Shires, Robert Chesney',
      uuid: '34a5ef60-ae87-49cd-9ca8-eb3ca156c79d',
      thumbnail: '/external_content_13023.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 10,
      title: 'Confronting an "Axis of Cyber"?',
      author: 'Fabio Rugge',
      uuid: '28482cc9-81ee-48d9-8127-5814e918ccea',
      thumbnail: '/external_content_7563.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 11,
      title: 'AI in the Age of Cyber-Disorder',
      author: 'Fabio Rugge',
      uuid: '29500f8d-03e6-4f9e-9df4-0b1412eec0d5',
      thumbnail: '/external_content_11250.jpg', // Provide the path to the thumbnail image
    },
    {
      id: 12,
      title: 'Routledge Handbook of International Cybersecurity',
      author: 'Eneken Tikk',
      uuid: 'dab780ec-7d1c-4245-9e02-d254c992f4c3',
      thumbnail: '/external_content_6491.jpg', // Provide the path to the thumbnail image
    },
    // Add more books as needed
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBookUuid, setSelectedBookUuid] = useState(null);

  const handleOpenModal = (uuid) => {
    setSelectedBookUuid(uuid);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedBookUuid(null);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 5 cards per row for larger screens
    slidesToScroll: 1,
    responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 4 } },
        { breakpoint: 768, settings: { slidesToShow: 3 } },
        { breakpoint: 480, settings: { slidesToShow: 2 } },
    ],
};

  return (
    <div className="cardgrid-container">
      <h4>Flagship Law Collection eBooks</h4>
      <Slider {...sliderSettings}>
        {books.map((book) => (
          <div key={book.id} className="book-card">
            <img src={book.thumbnail} alt={book.title} className="book-thumbnail" />
            <p
              className="book-title"
              onClick={() => handleOpenModal(book.uuid)}
            >
              {book.title}
            </p>
            <p className="book-author">by {book.author}</p>
          </div>
        ))}
      </Slider>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="article-details-title"
        aria-describedby="article-details-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '80vh',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
          }}
        >
          {selectedBookUuid && (
            <ArticleDetailsDSpace
              selectedArticleId={selectedBookUuid}
              closeModal={handleCloseModal}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Cardgrid;
