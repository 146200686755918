import React from 'react';
import '../components/AboutUs.css'; // Make sure the CSS is in the same directory or update the path accordingly.
import Cardgrid1 from './Cardgrid1';
import Cardgrid2 from './Cardgrid2';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <section className="about-us-section">
      <h2>UPSIFS Digital Library</h2>
        <p>
        We are excited to announce the launch of our new digital library, featuring over 10,000+ eBooks and 2,000+ journals in the field of forensic science, legal studies and cyber security. This comprehensive resource is designed to support students, researchers, and professionals by providing easy access to a vast collection of academic and scientific literature.
        </p>
        <p>Our digital library aims to enhance the learning experience and promote research in forensic science by offering a wide range of materials, including textbooks, research papers, thesis, and more. Whether you are a student looking for study materials, a researcher seeking the latest findings, or a professional staying updated with industry trends, our digital library has something for everyone.
        </p>
        <p>
        Explore our extensive collection today and take advantage of the wealth of knowledge at your fingertips!
        </p>
      </section><br></br>
      <section className="about-us-section">
        <h2>Featured Digital Library Content</h2>
        <Cardgrid1 />
        <Cardgrid2 />
      </section>
    </div>
  );
};

export default AboutUs;
